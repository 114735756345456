import React, { useCallback, useRef } from 'react';
import scrollIcon from '../../assets/scroll.svg';
import './Home.scss';

const Home = () => {
  const containerRef = useRef();

  const handleScroll = useCallback(() => {
    containerRef && containerRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className='sp-home'>
      <section className='sp-home__blank'>
        <img className='bounce2' src={scrollIcon} alt='scroll' height={50} onClick={handleScroll}/>
      </section>

      <div className='sp-home__phone' ref={containerRef}>
        <div className='sp-home__phone__half'>
          <h1>Sorin Popescu</h1>
          <p>“Photography is a way of feeling, of touching, of loving. What you have caught on film is captured forever… It remembers little things, long after you have forgotten everything.”</p>
          <p>| Aaron Siskind |</p>
        </div>
      </div>

      <div className='sp-home__container' ref={containerRef}>
        <div className='sp-home__container__content'>
          <div className='sp-home__container__content__half'>
            <h1>Sorin Popescu</h1>
            <p>“Photography is a way of feeling, of touching, of loving. What you have caught on film is captured forever… It remembers little things, long after you have forgotten everything.”</p>
            <p>| Aaron Siskind |</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
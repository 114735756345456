import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MaterialIcon from 'react-google-material-icons';
import logoImg from '../../assets/logo/logo_alb.png';
import { list } from './list';
import './Header.scss';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const isActive = useCallback((route) => {
        if (location.pathname.split('/')[1] === route) {
            return {
                color: 'white'
            };
        }

        if (!location.pathname.split('/')[1] && route === '/') {
            return {
                color: 'white'
            };
        }

        return null;
    }, [location]);

    const handleGoTo = useCallback((path) => {
        navigate(path);
        setMenuOpen(false);
    }, [navigate]);

    return (
        <header className='sp-header'>
            <div className='sp-header__logo'>
                <img src={logoImg} alt='logo' width={250}/>
            </div>

            <div className='sp-header__menu' onClick={() => setMenuOpen(!menuOpen)}>
                <MaterialIcon icon="menu" />
            </div>

            {
                menuOpen &&
                <div className='sp-header__hamburger'>
                    {
                        list.map(item => (
                            <li key={item.route} onClick={() => handleGoTo(item.route)}>{item.title}</li>
                        ))
                    }
                </div>
            }

            <nav className='sp-header__nav'>
                <ul className='sp-header__nav__list'>
                    {
                        list.map(item => (
                            <li style={isActive(item.route)} key={item.route} onClick={() => handleGoTo(item.route)}>{item.title}</li>
                        ))
                    }
                </ul>
            </nav>
        </header>
    );
};

export default Header;
import { CONTACT, HOME, MEMORIES } from "../../constants/constants";

export const list = [
    {
        route: HOME,
        title: 'HOME'
    }, {
        route: MEMORIES,
        title: 'MEMORIES'
    }, {
        route: CONTACT,
        title: 'CONTACT'
    }
];
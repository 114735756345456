import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getAllImages } from '../../firebase';
import './Memories.scss';

const ImageGrid = ({ images }) => {
  return (
    <div className="image-grid">
      {images.slice(0, 100).map((url, index) => (
        <img key={index} src={url} alt={`${index}`} className="grid-image" />
      ))}
    </div>
  );
};

const Memories = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [imagesUrl, setImagesUrl] = useState([]);

  const loadImages = async () => {
    setIsFetching(true);
    const { downloadURLs, nextPageToken: newPageToken } = await getAllImages(20, nextPageToken);
    setImagesUrl(prevImages => [...prevImages, ...downloadURLs]);
    setNextPageToken(newPageToken);
    setIsFetching(false);
  };

  useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='sp-memories'>
      <h2>Memories</h2>
      <div className='sp-memories__container'>
        {
          imagesUrl?.length === 0 && isFetching &&
          <LoadingIndicator />
        }
        <ImageGrid images={imagesUrl} />
        {/* {
          imagesUrl?.map((img, idx) => (
            <img src={img} style={{ height: idx % 5 === 0 ? '300px': '150px' }} alt='img' key={idx.toString()} />
          ))
        } */}
      </div>
      {nextPageToken && (
        <button className='sp-memories__load-button' onClick={loadImages} disabled={isFetching}>
          {isFetching ? 'Se incarca...' : 'Incarca mai multe'}
        </button>
      )}
    </div>
  );
};

export default Memories;
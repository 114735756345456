import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo/logo_gri.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import whatsapp from '../../assets/whatsapp.png';
import LoadingIndicator from '../LoadingIndicator';
import { sendMail } from '../../utils/sendMail';
import './Footer.scss';

const Footer = () => {
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [resp, setResp] = useState(null);

    const isVisible = useMemo(() => {
        return location.pathname !== '/contact';
    }, [location]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setFetching(true);
        const obj = {};

        obj.name = e.target[0].value;
        obj.mail = e.target[1].value;
        obj.phone = e.target[2].value;
        obj.message = e.target[3].value;

        sendMail(obj).then(response => {
            return response.text();
        }).then(result => {
            setResp(result);
            setFetching(false);
        }).catch(err => {
            console.log(err);
            setFetching(false);
        });
    }, []);

    return (
        <footer className='sp-footer'>
            {
                isVisible &&
                <div className='sp-footer__container'>
                    <section className='sp-footer__container__work'>
                        <h2>Let's work together</h2>
                        <p>Let’s collaborate to bring your vision to life, capturing moments that tell your unique story with creativity and passion.</p>
                        <div className='sp-footer__container__work__social'>
                            <a href='https://www.facebook.com/Pictureoflifephotography' target='_blank' rel='noreferrer'>
                                <img src={facebook} alt='Facebbok' />
                            </a>
                            <a href='https://www.instagram.com/pol_stories/' target='_blank' rel='noreferrer'>
                                <img src={instagram} alt='Instagram' />
                            </a>
                            <a href="https://wa.me/40774492236" target="_blank" rel="noreferrer">
                                <img src={whatsapp} alt='Whatsapp' />
                            </a>
                        </div>
                        <p className='sp-footer__container__work__copyright'>Copyright &copy; polstories.ro | 2024</p>
                    </section>
                    <section className='sp-footer__container__logo'>
                        <img src={logo} alt="logo" width={250} />
                    </section>
                    <section className='sp-footer__container__contact'>
                        <form className="sp-contact__form" onSubmit={handleSubmit}>
                            <input className="sp-contact__form__input" type="text" name='name' placeholder='Nume' required/>
                            <input className="sp-contact__form__input" type="email" name='email' placeholder='E-mail' required/>
                            <input className="sp-contact__form__input" type="tel" name='phone' placeholder='Telefon' required/>
                            <textarea className="sp-contact__form__textarea" name='message' placeholder='Mesaj' required/>
                            {
                                !fetching &&
                                <input className="sp-contact__form__button" type="submit" value="Trimite" />
                            }
                            {
                                fetching &&
                                <LoadingIndicator />
                            }
                            {
                                resp &&
                                <p style={{ color: 'green' }}>Mesaj trimis cu success.</p>
                            }
                        </form>
                    </section>

                    <img className='sp-footer__container__slogo' src={logo} alt="logo" />
                    <p className='sp-footer__container__copyright'>Copyright &copy; polstories.ro | 2024</p>
                </div>
            }
        </footer>
    );
};

export default Footer;
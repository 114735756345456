import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { HOME, MEMORIES, CONTACT } from "./constants/constants";
import Home from "./pages/Home";
import Memories from "./pages/Memories";
import Contact from "./pages/Contact";
import './App.css';

function App() {
  return (
    <BrowserRouter basename="/">
      <div className="App">
        <Header />
          <Routes>
            <Route path={HOME} element={<Home />} />
            <Route path={MEMORIES} element={<Memories />} />
            <Route path={CONTACT} element={<Contact />} />
          </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, list, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBeovlNfpnZYDZN0agKOU2U0U4tPEwY5Ao",
  authDomain: "portofolio-187b3.firebaseapp.com",
  projectId: "portofolio-187b3",
  storageBucket: "portofolio-187b3.appspot.com",
  messagingSenderId: "1069269276734",
  appId: "1:1069269276734:web:c73038fc850f799eb3196f",
  measurementId: "G-R7BCBQPFQK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const storage = getStorage(app);

const storageRef = ref(storage);

export const getAllImages = async (maxResults = 20, pageToken = null) => {
  try {
    const res = await list(storageRef, { maxResults, pageToken });
    const downloadURLs = [];

    for (const itemRef of res.items) {
        const url = await getDownloadURL(itemRef);
        downloadURLs.push(url);
    }

    return { downloadURLs, nextPageToken: res.nextPageToken };
} catch (error) {
    console.error('Error listing images with pagination:', error);
    return { downloadURLs: [], nextPageToken: null };
}
};
